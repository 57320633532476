<template>
    <CBadge :color="row.item.wrong_email ? 'danger' : 'success' ">
        {{ row.item.wrong_email ? $t('common.button.yes') : $t('common.button.no') }}
    </CBadge>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>